import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '@components/Header';

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [submittingInfo, setSubmittingInfo] = useState<boolean>(false);

  const submitLogin = () => {
    if (submittingInfo) return;
    setSubmittingInfo(true);

    const validators = {
      email: email.length > 0,
      password: password.length > 0,
    };

    if (!Object.values(validators).every((v) => v)) {
      // eslint-disable-next-line no-alert
      alert('Please fill out all required fields');
      setSubmittingInfo(false);
      return;
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.data.authenticated) {
          navigate('/staff', { replace: true });
        } else {
          // eslint-disable-next-line no-alert
          alert('Incorrect email or password');
        }
        setSubmittingInfo(false);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('An error occurred. Please try again later.');
        setSubmittingInfo(false);
      });
  };

  return (
    <>
      <Header />

      <Box
        sx={{
          width: '100%',
          pt: 4,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Login</Typography>
      </Box>

      <Box
        sx={{
          pl: '10%',
          width: '80%',
          pt: 4,
          display: 'grid',
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={3} />
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={3} />
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={3} />
          <Grid item xs={6}>
            <Button fullWidth variant="contained" onClick={() => submitLogin()}>
              {submittingInfo ? <CircularProgress /> : 'Login'}
            </Button>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Box>
    </>
  );
}

export default Login;
