import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridToolbar,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

dayjs.extend(dayOfYear);

interface Row {
  id: string;
  studentFirstName: string;
  studentLastName: string;
  studentEmail: string;
  studentPhone: string;
  parentFirstName: string;
  parentLastName: string;
  parentEmail: string;
  parentPhone: string;
  selectedTemplate: string;
  parentAdText: string;
  parentAdPhotos: string;
  specialRequests: string;
}

function ParentAds() {
  const [requestedData, setRequestedData] = useState<boolean>(false);
  const [searchYear, setSearchYear] = useState<number | null>(null);
  const [downloadYear, setDownloadYear] = useState<number | null>(null);

  const [rows, setRows] = useState<Row[]>([]);

  const [openEditMenu, setOpenEditMenu] = useState<boolean>(false);
  const [editRow, setEditRow] = useState<Row | null>(null);

  const downloadYearRegistersParams = useMemo(() => {
    const params = new URLSearchParams();
    params.append('year', JSON.stringify(downloadYear));

    return params;
  }, [downloadYear]);

  useEffect(() => {
    if (!requestedData) return;

    fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/parent_ads`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        year: searchYear,
      }),
    })
      .then((res) => res.json())
      .then((res) => setRows(res.data.rows));
  }, [requestedData]);

  const columns: readonly GridColDef[] = [
    { field: 'id', headerName: 'ID', minWidth: 170 },
    {
      field: 'studentFirstName',
      headerName: 'Student First Name',
      minWidth: 170,
    },
    {
      field: 'studentLastName',
      headerName: 'Student Last Name',
      minWidth: 170,
    },
    { field: 'studentEmail', headerName: 'Student Email', minWidth: 170 },
    { field: 'studentPhone', headerName: 'Student Phone', minWidth: 170 },
    {
      field: 'parentFirstName',
      headerName: 'Parent First Name',
      minWidth: 170,
    },
    {
      field: 'parentLastName',
      headerName: 'Parent Last Name',
      minWidth: 170,
    },
    { field: 'parentEmail', headerName: 'Parent Email', minWidth: 170 },
    { field: 'parentPhone', headerName: 'Parent Phone', minWidth: 170 },
    {
      field: 'selectedTemplate',
      headerName: 'Selected Template',
      minWidth: 170,
    },
    { field: 'parentAdText', headerName: 'Parent Ad Text', minWidth: 170 },
    {
      field: 'parentAdPhotos',
      headerName: 'Parent Ad Photos',
      minWidth: 170,
      valueGetter: (value: string[]) =>
        value.length > 0 ? value.join(', ') : '',
    },
    { field: 'specialRequests', headerName: 'Special Requests', minWidth: 170 },
    {
      field: 'edit',
      type: 'actions',
      headerName: 'Edit',
      minWidth: 50,
      cellClassName: 'edit',
      getActions: ({ row }: GridRowParams) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => {
            setOpenEditMenu(true);
            setEditRow(row);
          }}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Parent Ads</Typography>
      </Box>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setRequestedData(true);
            }}
          >
            Get Parent Ads for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={searchYear}
            onChange={(e) => {
              setSearchYear(parseInt(e.target.value, 10));
              setRequestedData(false);
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            href={`${
              process.env.REACT_APP_BACKEND_URL
            }/staff/download_parent_ads${
              downloadYearRegistersParams.size > 0 ? '?' : ''
            }${downloadYearRegistersParams.toString()}`}
            target="_blank"
            rel="noreferrer"
          >
            Download CSV for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={downloadYear}
            onChange={(e) => {
              setDownloadYear(parseInt(e.target.value, 10));
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      {requestedData && (
        <Box
          sx={{
            pl: '10%',
            width: '80%',
            pt: 4,
            display: 'grid',
            height: '800px',
          }}
        >
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            ignoreValueFormatterDuringExport
            rows={rows}
            columns={columns}
            getRowId={(row) => row.submissionId}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  fileName: `parent_ads_${searchYear}`,
                },
              },
            }}
          />
        </Box>
      )}

      <Dialog
        fullWidth
        open={openEditMenu}
        scroll="body"
        maxWidth="sm"
        onClose={() => setOpenEditMenu(false)}
      >
        <DialogTitle>Edit Parent Ads Bioform</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <TextField label="ID" value={editRow?.id} disabled />
            <TextField
              label="Student First Name"
              value={editRow?.studentFirstName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, studentFirstName: e.target.value });
              }}
            />
            <TextField
              label="Student Last Name"
              value={editRow?.studentLastName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, studentLastName: e.target.value });
              }}
            />
            <TextField
              label="Student Email"
              value={editRow?.studentEmail}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, studentEmail: e.target.value });
              }}
            />
            <TextField
              label="Student Phone"
              value={editRow?.studentPhone}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, studentPhone: e.target.value });
              }}
            />
            <TextField
              label="Parent First Name"
              value={editRow?.parentFirstName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentFirstName: e.target.value });
              }}
            />
            <TextField
              label="Parent Last Name"
              value={editRow?.parentLastName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentLastName: e.target.value });
              }}
            />
            <TextField
              label="Parent Email"
              value={editRow?.parentEmail}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentEmail: e.target.value });
              }}
            />
            <TextField
              label="Parent Phone"
              value={editRow?.parentPhone}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentPhone: e.target.value });
              }}
            />
            <TextField
              label="Selected Template"
              value={editRow?.selectedTemplate}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, selectedTemplate: e.target.value });
              }}
            />
            <TextField
              label="Parent Ad Text"
              value={editRow?.parentAdText}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentAdText: e.target.value });
              }}
            />
            <TextField
              label="Parent Ad Photos"
              value={editRow?.parentAdPhotos}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentAdPhotos: e.target.value });
              }}
            />
            <TextField
              label="Special Requests"
              value={editRow?.specialRequests}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, specialRequests: e.target.value });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditMenu(false)}>Cancel</Button>
          <Button
            color="error"
            onClick={() => {
              if (editRow) {
                fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/parent_ads`, {
                  method: 'DELETE',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    submissionId: editRow.id,
                  }),
                })
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/parent_ads`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              if (editRow) {
                fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/parent_ads`, {
                  method: 'PUT',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    id: editRow.id,
                    selectedTemplate: editRow.selectedTemplate,
                    parentAdText: editRow.parentAdText,
                    parentAdPhotos: editRow.parentAdPhotos.split(','),
                    specialRequests: editRow.specialRequests,
                  }),
                })
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/parent_ads`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ParentAds;
