import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridToolbar,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { ClearIcon } from '@mui/x-date-pickers';

dayjs.extend(dayOfYear);

interface OfficerPosition {
  name: string;
  position: string;
}

interface Row {
  submissionId: string;
  orgName: string;
  orgDescription: string;
  orgPhotoLink: string;
  orgEmail: string;
  orgOfficers: OfficerPosition[];
}

function OrganizationBioforms() {
  const [requestedData, setRequestedData] = useState<boolean>(false);
  const [searchYear, setSearchYear] = useState<number | null>(null);
  const [downloadYear, setDownloadYear] = useState<number | null>(null);

  const [rows, setRows] = useState<Row[]>([]);

  const [openEditMenu, setOpenEditMenu] = useState<boolean>(false);
  const [editRow, setEditRow] = useState<Row | null>(null);

  const downloadYearRegistersParams = useMemo(() => {
    const params = new URLSearchParams();
    params.append('year', JSON.stringify(downloadYear));

    return params;
  }, [downloadYear]);

  useEffect(() => {
    if (!requestedData) return;

    fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/organization_bioforms`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        year: searchYear,
      }),
    })
      .then((res) => res.json())
      .then((res) => setRows(res.data.rows));
  }, [requestedData]);

  const columns: readonly GridColDef[] = [
    { field: 'submissionId', headerName: 'Submission ID', minWidth: 170 },
    {
      field: 'orgName',
      headerName: 'Organization Name',
      minWidth: 170,
    },
    {
      field: 'orgDescription',
      headerName: 'Organization Description',
      minWidth: 170,
    },
    {
      field: 'orgPhotoLink',
      headerName: 'Organization Photo Link',
      minWidth: 170,
    },
    { field: 'orgEmail', headerName: 'Organization Email', minWidth: 170 },
    {
      field: 'orgOfficers',
      headerName: 'Organization Officers',
      minWidth: 170,
      valueGetter: (value: OfficerPosition[]) =>
        value.length > 0
          ? value
              .map(
                (activity: OfficerPosition) =>
                  `${activity.name}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join(', ')
              .concat('.')
          : '',
    },
    {
      field: 'finalBioform',
      headerName: 'Bioform',
      minWidth: 170,
    },
    {
      field: 'edit',
      type: 'actions',
      headerName: 'Edit',
      minWidth: 50,
      cellClassName: 'edit',
      getActions: ({ row }: GridRowParams) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => {
            setOpenEditMenu(true);
            setEditRow(row);
          }}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Organization Bioforms</Typography>
      </Box>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setRequestedData(true);
            }}
          >
            Get Bioforms for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={searchYear}
            onChange={(e) => {
              setSearchYear(parseInt(e.target.value, 10));
              setRequestedData(false);
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            href={`${
              process.env.REACT_APP_BACKEND_URL
            }/staff/download_yearbook_orders${
              downloadYearRegistersParams.size > 0 ? '?' : ''
            }${downloadYearRegistersParams.toString()}`}
            target="_blank"
            rel="noreferrer"
          >
            Download CSV for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={downloadYear}
            onChange={(e) => {
              setDownloadYear(parseInt(e.target.value, 10));
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      {requestedData && (
        <Box
          sx={{
            pl: '10%',
            width: '80%',
            pt: 4,
            display: 'grid',
            height: '800px',
          }}
        >
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            ignoreValueFormatterDuringExport
            rows={rows}
            columns={columns}
            getRowId={(row) => row.submissionId}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  fileName: `yearbook_orders_${searchYear}`,
                },
              },
            }}
          />
        </Box>
      )}

      <Dialog
        fullWidth
        open={openEditMenu}
        scroll="body"
        maxWidth="sm"
        onClose={() => setOpenEditMenu(false)}
      >
        <DialogTitle>Edit Freshman Register Bioform</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update this person&apos;s bioform.
          </DialogContentText>

          <br />

          <Stack direction="column" spacing={2}>
            <TextField
              label="Submission ID"
              value={editRow?.submissionId}
              disabled
            />
            <TextField
              label="Organization Name"
              value={editRow?.orgName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, orgName: e.target.value });
              }}
            />
            <TextField
              label="Organization Description"
              value={editRow?.orgDescription}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, orgDescription: e.target.value });
              }}
            />
            <TextField
              label="Organization Photo Link"
              value={editRow?.orgPhotoLink}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, orgPhotoLink: e.target.value });
              }}
            />
            <TextField
              label="Organization Email"
              value={editRow?.orgEmail}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, orgEmail: e.target.value });
              }}
            />

            <>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Typography>Organization Officers</Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editRow?.orgOfficers)
                        setEditRow({
                          ...editRow,
                          orgOfficers: [
                            ...editRow.orgOfficers,
                            { name: '', position: '' },
                          ],
                        });
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {editRow?.orgOfficers && editRow?.orgOfficers.length > 0 && (
                <Grid item xs={12}>
                  <List
                    sx={{
                      padding: 0,
                    }}
                  >
                    {editRow?.orgOfficers.map((activity, ind) => (
                      <ListItem key={ind}>
                        <ListItemIcon
                          onClick={(_e) =>
                            setEditRow({
                              ...editRow,
                              orgOfficers: editRow?.orgOfficers.filter(
                                (_, i) => i !== ind,
                              ),
                            })
                          }
                        >
                          <ClearIcon
                            style={{
                              cursor: 'pointer',
                              color: 'red',
                            }}
                          />
                        </ListItemIcon>
                        <Grid container direction="row" alignItems="center">
                          <Grid item xs={8}>
                            <TextField
                              fullWidth
                              label="Name"
                              variant="outlined"
                              value={activity.name}
                              onChange={(e) =>
                                setEditRow({
                                  ...editRow,
                                  orgOfficers: editRow?.orgOfficers.map(
                                    (val, i) =>
                                      i === ind
                                        ? {
                                            ...val,
                                            name: e.target.value,
                                          }
                                        : val,
                                  ),
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth
                              label="Position"
                              variant="outlined"
                              value={activity.position}
                              onChange={(e) =>
                                setEditRow({
                                  ...editRow,
                                  orgOfficers: editRow?.orgOfficers.map(
                                    (val, i) =>
                                      i === ind
                                        ? {
                                            ...val,
                                            position: e.target.value,
                                          }
                                        : val,
                                  ),
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
            </>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditMenu(false)}>Cancel</Button>
          <Button
            color="error"
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/organization_bioforms`,
                  {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/organization_bioforms`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/organization_bioforms`,
                  {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                      orgName: editRow.orgName,
                      orgDescription: editRow.orgDescription,
                      orgPhotoLink: editRow.orgPhotoLink,
                      orgEmail: editRow.orgEmail,
                      orgOfficers: editRow.orgOfficers,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/organization_bioforms`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OrganizationBioforms;
