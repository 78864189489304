export const concurrentMastersList = [
  'Applied Math (S.M.)',
  'Applied Physics (S.M.)',
  'Bioengineering (S.M.)',
  'Chemistry and Chemical Biology (A.M.)',
  'Computer Science and Engineering (S.M.)',
  'Computer Science (S.M.)',
  'Electrical Engineering (S.M.)',
  'Environmental Science and Engineering (S.M.)',
  'Germanic Languages and Literatures (A.M.)',
  'Linguistics (A.M.)',
  'Mathematics (A.M.)',
  'Materials Science and Mechanical Engineering (S.M.)',
  'Middle Eastern Studies (A.M.)',
  'Near Eastern Languages Civilizations (A.M.)',
  'Physics (A.M.)',
  'Regional Studies - East Asia (A.M.)',
  'Regional Studies - Russia, Eastern Europe, and Central Asia (A.M.)',
  'Statistics (A.M.)',
];
