import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConstantsSliceType {
  currentGraduatingYear: number;
  currentIncomingYear: number;

  summerBegins: Date;
  summerEnds: Date;

  yearbookPreOrderDeadline: Date;
  yearbookOrderDeadline: Date;
  yearbookOrderLateDeadline: Date;
  yearbookParentAdDeadline: Date;
  yearbookParentAdLateDeadline: Date;

  registerOrderDeadline: Date;
  registerParentAdDeadline: Date;
  registerParentAdLateDeadline: Date;

  bioformDeadline: Date;
  bioformLateDeadline: Date;
  alternatePhotoDeadline: Date;

  candidsDeadline: Date;
  orgBioformOpen: Date;
  orgBioformDeadline: Date;

  yearbookPreOrderPrice: number;
  yearbookOrderPrice: number;
  registerPreOrderPrice: number;
  registerOrderPrice: number;
  shippingPrice: number;
  internationalShippingPrice: number;
  embossPrice: number;
  yearbookPastEditionsPrice: number;
  registerPastEditionsPrice: number;

  yearbookSilverPrice: number;
  yearbookPlatinumPrice: number;
  yearbookJohnHarvardPrice: number;
  yearbookCrimsonPrice: number;
  registerSilverPrice: number;
  registerPlatinumPrice: number;
  registerJohnHarvardPrice: number;

  cloudinaryCloudName: string;
  cloudinaryUploadPreset: string;
  registerPhotoFolder: string;
  registerParentAdFolder: string;
  yearbookParentAdFolder: string;
  orgPhotoFolder: string;
}

const inspectorInitialState: ConstantsSliceType = {
  currentGraduatingYear: 2025,
  currentIncomingYear: 2028,

  summerBegins: new Date('June 1, 2024'),
  summerEnds: new Date('September 3, 2024'),

  yearbookPreOrderDeadline: new Date('January 31, 2025'),
  yearbookOrderDeadline: new Date('March 23, 2025'),
  yearbookOrderLateDeadline: new Date('March 23, 2025'),
  yearbookParentAdDeadline: new Date('December 15, 2024'),
  yearbookParentAdLateDeadline: new Date('December 22, 2024'),

  registerOrderDeadline: new Date('September 1, 2024'),
  registerParentAdDeadline: new Date('July 15, 2024'),
  registerParentAdLateDeadline: new Date('July 15, 2024'),

  bioformDeadline: new Date('October 27, 2024'),
  bioformLateDeadline: new Date('November 3, 2024'),
  alternatePhotoDeadline: new Date('December 15, 2024'),

  candidsDeadline: new Date('January 15, 2025'),
  orgBioformOpen: new Date('September 3, 2024'),
  orgBioformDeadline: new Date('November 15, 2024'),

  yearbookPreOrderPrice: 110,
  yearbookOrderPrice: 140,
  registerPreOrderPrice: 45,
  registerOrderPrice: 50,
  shippingPrice: 10,
  internationalShippingPrice: 35,
  embossPrice: 30,
  yearbookPastEditionsPrice: 140,
  registerPastEditionsPrice: 50,

  yearbookSilverPrice: 300,
  yearbookPlatinumPrice: 500,
  yearbookCrimsonPrice: 800,
  yearbookJohnHarvardPrice: 1600,
  registerSilverPrice: 150,
  registerPlatinumPrice: 400,
  registerJohnHarvardPrice: 800,

  cloudinaryCloudName: '',
  cloudinaryUploadPreset: '',
  registerPhotoFolder: '',
  registerParentAdFolder: '',
  yearbookParentAdFolder: '',
  orgPhotoFolder: '',
};

const slice = createSlice({
  name: 'constants',
  initialState: inspectorInitialState,
  reducers: {
    setConstants(state, { payload }: PayloadAction<ConstantsSliceType>) {
      state = payload;

      if (payload.summerBegins)
        state.summerBegins = new Date(payload.summerBegins);
      if (payload.summerEnds) state.summerEnds = new Date(payload.summerEnds);
      if (payload.yearbookPreOrderDeadline)
        state.yearbookPreOrderDeadline = new Date(
          payload.yearbookPreOrderDeadline,
        );
      if (payload.yearbookOrderDeadline)
        state.yearbookOrderDeadline = new Date(payload.yearbookOrderDeadline);
      if (payload.yearbookOrderLateDeadline)
        state.yearbookOrderLateDeadline = new Date(
          payload.yearbookOrderLateDeadline,
        );
      if (payload.yearbookParentAdDeadline)
        state.yearbookParentAdDeadline = new Date(
          payload.yearbookParentAdDeadline,
        );
      if (payload.yearbookParentAdLateDeadline)
        state.yearbookParentAdLateDeadline = new Date(
          payload.yearbookParentAdLateDeadline,
        );
      if (payload.registerOrderDeadline)
        state.registerOrderDeadline = new Date(payload.registerOrderDeadline);
      if (payload.registerParentAdDeadline)
        state.registerParentAdDeadline = new Date(
          payload.registerParentAdDeadline,
        );
      if (payload.registerParentAdLateDeadline)
        state.registerParentAdLateDeadline = new Date(
          payload.registerParentAdLateDeadline,
        );
      if (payload.bioformDeadline)
        state.bioformDeadline = new Date(payload.bioformDeadline);
      if (payload.bioformLateDeadline)
        state.bioformLateDeadline = new Date(payload.bioformLateDeadline);
      if (payload.alternatePhotoDeadline)
        state.alternatePhotoDeadline = new Date(payload.alternatePhotoDeadline);
      if (payload.candidsDeadline)
        state.candidsDeadline = new Date(payload.candidsDeadline);
      if (payload.orgBioformOpen)
        state.orgBioformOpen = new Date(payload.orgBioformOpen);
      if (payload.orgBioformDeadline)
        state.orgBioformDeadline = new Date(payload.orgBioformDeadline);
    },
  },
});

export const { setConstants } = slice.actions;
export default slice.reducer;
