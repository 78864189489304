import { ListItem, Stack, Typography } from '@mui/material';

function SuccessParentAds() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ width: 1, height: '100vh' }}
    >
      <ListItem>
        <Typography variant="h1">
          Parent Ad Information Submission Successful
        </Typography>
      </ListItem>

      <ListItem>
        <Typography variant="h6">
          We have received your parent ad materials for your student. If you
          have any questions, we encourage you to reach out to our Parent Ads
          Coordinator at{' '}
          <a href="mailto:ads@harvardyearbook.com">ads@harvardyearbook.com</a>.
        </Typography>
      </ListItem>
    </Stack>
  );
}

export default SuccessParentAds;
