import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  useLoaderData,
} from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { useEffect } from 'react';
import FreshmanRegister from '@pages/FreshmanRegister';
import SuccessPayment from '@pages/success/SuccessPayment';
import Home from '@pages/Home';
import NotFound from '@pages/404';
import Login from '@pages/Login';
import StaffRouter from '@routers/StaffRouter';
import YearbookBioform from '@pages/YearbookBioform';
import YearbookOrder from '@pages/YearbookOrder';
import theme from '@styles/theme';
import ParentAdSubmission from '@pages/ParentAdSubmission';
import { useTypedDispatch } from '@redux/store';
import { setConstants } from '@redux/constants';
import SuccessOrder from '@pages/success/SuccessOrder';
import OrganizationBioform from '@pages/OrganizationBioform';
import SuccessBioform from '@pages/success/SuccessBioform';
import SuccessOrgBioform from '@pages/success/SuccessOrgBioform';
import SuccessParentAds from '@pages/success/SuccessParentAds';

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const authenticated: boolean = useLoaderData() as boolean;

  if (!authenticated) {
    return <Navigate to="/login" replace />;
  }
  return children;
}

async function authLoader() {
  const auth = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/verify`, {
    method: 'GET',
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((res) => res.data.authenticated);

  return auth;
}

const routesComponent = createRoutesFromElements(
  <Route path="/">
    <Route index element={<Home />} />
    <Route path="/freshman_register" element={<FreshmanRegister />} />
    <Route path="/janice_only_yearbook_bioform" element={<YearbookBioform />} />
    <Route path="/yearbook_order" element={<YearbookOrder />} />
    <Route path="/parent_ad_submission" element={<ParentAdSubmission />} />
    <Route path="/organization_bioform" element={<OrganizationBioform />} />
    <Route path="/success_payment" element={<SuccessPayment />} />
    <Route path="/success_order" element={<SuccessOrder />} />
    <Route path="/success_bioform" element={<SuccessBioform />} />
    <Route path="/success_orgbioform" element={<SuccessOrgBioform />} />
    <Route path="/success_parent_ads" element={<SuccessParentAds />} />

    <Route path="/login" element={<Login />} />
    <Route
      path="/staff/*"
      element={
        <ProtectedRoute>
          <StaffRouter />
        </ProtectedRoute>
      }
      loader={authLoader}
    />

    <Route path="*" element={<NotFound />} />
  </Route>,
);

const browserRouter = createBrowserRouter(routesComponent);

function App() {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/constants`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          dispatch(setConstants(res.data));
        }
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={browserRouter} />
    </ThemeProvider>
  );
}

export default App;
