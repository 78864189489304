export const languageCitationList = [
  'Afrikaans',
  'American Sign Language',
  'Amharic',
  'Classical Arabic',
  'Modern Standard Arabic',
  'Egyptian Arabic',
  'Sudanese Arabic',
  'Armenian',
  'Bosnian/Croatian/Serbian',
  'Catalan',
  'Chinese',
  'Literary Chinese',
  'Czech',
  'Danish',
  'Finnish',
  'French',
  'German',
  'Gikuyu',
  'Greek',
  'Modern Greek',
  'Gullah',
  'Classical Hebrew',
  'Modern Hebrew',
  'Hindi-Urdu',
  'Icelandic',
  'Igbo',
  'Italian',
  'Japanese',
  'Kinyarwanda',
  'Korean',
  'Latin',
  'Norwegian',
  'Persian',
  'Polish',
  'Portuguese',
  'Russian',
  'Sanskrit',
  'Somali',
  'Spanish',
  'Swahili',
  'Swedish',
  'Tamil',
  'Classical Tibetan',
  'Tigrinya',
  'Turkish',
  'Twi',
  'Ukrainian',
  'Uyghur',
  'Vietnamese',
  'Wolof',
  'Yiddish',
  'Yoruba',
  'Zulu',
];
