export const onCampusJobsList = [
  'Academic Resource Center',
  'Academic Resource Center (Peer Tutor Fellows) ',
  'Academic Resource Center (Peer Tutor) ',
  'Disability Access Office',
  'Administrative and Academic Affairs Office',
  'Admissions and Financial Aid Office',
  'Advanced Leadership Initiative',
  'Advising Programs Office',
  'Albanian Students Association',
  'Alcohol and Other Drug Services [AODS]',
  'Alumni Affairs and Development Office',
  'Alumni Association',
  'Alumni Association Reunions',
  'Arnold Arboretum',
  'Art Museums',
  'Art Museums',
  'Arts @ 29 Garden',
  'Ash Center for Democratic Governance and Innovation',
  'Athletics Department',
  'Barker Center Cafe',
  'Belfer Center',
  'Berkman Klein Center for Internet and Society',
  'Birkhoff Mathematical Library',
  'Box Office',
  'Bridge Program',
  "Building Manager's Office",
  'Building Services',
  'Bureau of Study Counsel',
  'Cabot Cafe',
  'Cabot House Third Space',
  'Cabot Science Library',
  'Cafe Gato Rojo',
  "Cambridge Queen's Head",
  'Camp Kesem',
  'Carpenter Center for the Visual Arts',
  'Center for African Studies',
  'Center for European Studies',
  'Center for Public Interest Careers (CPIC)',
  'Center for Public Leadership',
  'Center for the Environment',
  'Center for Wellness',
  'Chemistry and Chemical Biology Department ',
  'Chemistry and Chemical Biology Library',
  'Collection of Historical Scientific Instruments',
  'College Alumni Programs Office',
  'College Fund',
  'Committee of Student Life',
  'Communications Office',
  'Countway Library',
  'Course Assistant',
  'Course Facilitator',
  'Crimson Bikes',
  'Crimson Callers',
  'Crimson Summer Academy',
  'CS50 Production Team',
  'Currier House Bells Desk',
  'Dance Center',
  'David Rockefeller Center for Latin American Studies',
  'Department of Publications',
  'Derek Bok Center for Teaching and Learning',
  'Design Services',
  'Development and Alumni Relations Office',
  'Digital Strategy Office',
  'Divinity School Science, Culture and Religion Program',
  'Division of Continuing Education',
  'Donor Relations Office',
  'Dorm Crew',
  'Dorm Crew Captain',
  'Dorm Crew Office Manager',
  'Dudley Community Office',
  'Economics 10 Unit Review',
  'Economics Question Center',
  'Ed Portal',
  'Events and Information Center',
  'External Relations Office',
  'Faculty Support Services',
  'Fall Clean-Up',
  'Film Archive',
  'Film Studies Library',
  'Finance Office',
  'Financial Aid Initiative',
  'Fine Arts Library',
  'Food Literacy Project',
  'Fund and Alumni Relations',
  'Global Health Institute',
  'Greeter and Tour Guide Program',
  'Gutman Library',
  'Libraries [Harvard Libraries]',
  'Harvard Law Review',
  'Harvard Libraries',
  'Harvard Magazine',
  'Map Collection [Harvard Map Collection]',
  'Harvard Museum',
  'Harvard Press',
  'Harvard Recreation',
  'Harvard Review',
  'Harvard Student Agencies [HSA]',
  'Harvard Summer School',
  'Harvard-Yenching Library',
  'HarvardX',
  'Healthy Harvard',
  'Herbaria',
  'Hicks House Library',
  'Hillel',
  'Hiphop Archive and Research Institute',
  'HLS Office of Career Services',
  'Houghton Library',
  'House Advising Peer',
  'House Grille',
  'House Grille (Manager) ',
  'Housing Services',
  'Human Resources Office',
  'iLab',
  'Information Center Tour Guide',
  'Information Security Office',
  'Innovation Labs [i-lab]',
  'Institute for Quantitative Social Science',
  'Institute of Politics',
  'Interlibrary Loan Department',
  'International Office',
  'Intramurals Referee',
  'JFK Jr. Forum',
  'Kennedy School Campus Planning and Operations',
  'Kennedy School Library',
  'Korea Institute',
  'Lamont Cafe',
  'Lamont Library',
  'Language Resource Center',
  'Legal Aid Bureau',
  "Let's Go Publications",
  'Littauer Center',
  'Loeb Music Library',
  'Lowell House Tea',
  'Mahindra Humanities Center',
  'Mail and Distribution Services',
  'MCB Graphics',
  'Media and Technology Services',
  'Memorial Church',
  'Metalab',
  'Molecular and Cellular Biology',
  'Museum of Comparative Zoology',
  'Museum of Natural History',
  'Museums of Science and Culture',
  'Native American Program',
  'Observatory',
  'Office for Equity Diversity and Inclusion',
  'Office for Scholarly Communication',
  'Office for Student Services',
  'Office for Sustainability',
  'Office for the Arts',
  'Office of BGLTQ Student Life',
  'Office of Career Advancement',
  'Office of Career Services',
  'Office of International Affairs',
  'Office of International Education',
  'Office of International Programs',
  'Office of News and Public Affairs',
  'Office of Student Life',
  'Office of Technology Development',
  'Office of the Controller',
  'Office of the Dean',
  'Office of the Dean of Students',
  'Office of the General Counsel',
  'Office of the President',
  'Office of Undergraduate Education',
  'Office of Undergraduate Research and Fellowships',
  'Olveczky Lab Organismic and Evolutionary Biology',
  'Outings and Innings',
  'Peabody Museum',
  'Peabody Museum Press',
  'Peer Concentration Advisor',
  'Peer Concentration Counselor',
  'Peer Research Fellow',
  'Peer Speaking Tutor',
  'Petrie-Flom Center',
  'Physics Research Library',
  'Planned Giving Office',
  'Planning and Project Management',
  'Pre-Orientation Committee',
  'Priscilla Chan Stride Service Scholarship',
  'Program in General Education',
  'Project on American Indian Economic Development',
  'Project Zero',
  'Public Affairs and Communication',
  'Public Service Network',
  'Publications and Alumni Relations Office',
  'Radcliffe Fellowship Program',
  'Radcliffe Institute for Advanced Study',
  'Radcliffe Research Partnership',
  'Real Colegio Complutense',
  'Real Estate Services',
  'Registrar and Financial Aid Office',
  "Registrar's Office",
  'Religions and the Practice of Peace',
  'Religious Literacy Project',
  'Research',
  'Residential Life Subcommittee',
  'Sackler Museum',
  'Sanders Theater',
  'Schlesinger Library',
  'School of Engineering and Applied Sciences',
  'Semitic Museum',
  'Society of Fellows',
  'Spring Clean-Up',
  'Steering Committee for Belonging and Inclusion',
  'Student Affairs',
  'Student Employment Office',
  'Student Event Services',
  'Student Financial Services',
  'Student Information System',
  'Student Organization Center at Hilles',
  'Student Receivables Office',
  'Teaching Fellow',
  'The American Repertory Theater',
  'The COOP',
  'The Family Van',
  'Tozzer Library',
  'Transportation Services',
  'Undergraduate Admissions Council',
  'Undergraduate Minority Recruitment Program',
  'Undergraduate Teacher Education Program',
  'University Archives',
  'University Development Office',
  'University Dining Services [HUDS]',
  'University Health Services [HUHS]',
  'University Information Technology (HUIT)',
  'Urban Planning and Design Office',
  "Visitor's Center Tour Guide",
  'W.E.B DuBois Institute',
  'Weatherhead Center for International Affairs',
  'Web Publishing Office',
  'Weissman Preservation Center',
  'Widener Library',
  "Women's Center",
  'Writing Center',
  'Writing Program',
  'X-Media Lab',
  'Yard Operations',
  'Yenching Institute',
  'Zero Waste Team',
  'Frances Loeb Library',
  'Student Development Partners',
  'Strategic Training Initiative for the Prevention of Eating Disorders (STRIPED)',
  'Harvard University Employees Credit Union',
  'Cambridge Common Voices',
  'Instituto Cervantes Observatory',
  'Harvard Foundation for Intercultural and Race Relations',
  'Harvard Alumni Association',
  'Harvard Kennedy School',
  'Social Innovation and Change Initiative',
  'Mellon Mays Undergraduate Fellowship',
  'HLS Office of Public Interest Advising',
  'Davis Center for Russian and Eurasian Studies',
  'Presidential Public Service Fellowship',
  'Office of the Vice Provost for Advances in Learning',
  'Classics Department',
  'Museum of the Ancient Near East',
  'Social Studies Department',
  'William Monroe Trotter Collaborative',
  'Instructional Machine Shop',
  'Lowell House Office',
  'Adams Hair Project',
  'House Dance Instructor',
  'Alex Patel Teaching Fellow',
  'Harvard First Generation Program',
  'Earth and Planetary Sciences Department',
  'Phillips Brooks House Association',
  'Lemann Program on Creativity and Entrepreneurship',
  'Climate Leaders Program',
  'Choruses New Music Initiative',
  'Muslim American Studies Working Group',
  'Committee on Ethnicity, Migration, Rights',
  'Undergraduate Technology Innovation Fellows Program',
  'Humanitarian Initiative',
  'Harvard Salata Institute',
  'Harvard First-Year Experience Office',
  'Peer Advising Fellow',
  'Emerging Leaders Program',
  'Mindich Program in Engaged Scholarship',
  'Lowell House Legacies Project',
  'REEF Makerspace',
  'Sociology Department',
  'Behavioral Lab',
  'Economics Department',
  'Assistive Technology Center',
  'Center for International Development',
  'Center for Public Service and Engaged Scholarship',
  'Harvard Grid',
  'Intercollegiate Civil Disagreement Partnership Fellowship',
  'Harvard Law School Library',
  'Intellectual Vitality Fellow',
];
