export const clubSportsList = [
  'Aikikai Club',
  'Archery Club',
  'Badminton Club',
  'Ballroom Dance Team',
  'Baseball Club',
  // 'Billiards Club',
  // 'Bowling Club',
  'Boxing Club',
  'Broomball Club',
  // 'Capoeira Club',
  'Cheerleading Club',
  'Climbing Club',
  // 'Cornhole Club',
  'Cricket Club',
  "Basketball Club (Men's Classics)",
  "Basketball Club (Men's Hoopsters)",
  'Crimson Dance Team',
  'Curling Club',
  'Cycling Club',
  // 'Dragon Boat',
  'Fencing Club',
  'Field Hockey Club',
  'Figure Skating Club',
  'Flag Football Club',
  'Futsal Club',
  'Golf Club',
  'Hapkido Club',
  'Jiu Jitsu Club',
  'Karate Club',
  'Kendo Club',
  'Krav Maga Club',
  "Men's Ice Hockey Club",
  "Men's Lacrosse Club",
  "Men's Rugby Club",
  "Men's Soccer Club",
  "Men's Tennis Club",
  "Men's Ultimate Frisbee Club",
  "Men's Volleyball Club",
  'Nordic Skiing Club',
  'Pistol Club',
  'Polo Club',
  'Powerlifting Club',
  'Project SWIM Club',
  'Quadball Club',
  'Running Club',
  'Sailing Club',
  'Scuba Diving Club',
  'Shooting Club',
  'Ski Club',
  // 'Spikeball Club',
  'Squash Club',
  'Swimming Club',
  'Table Tennis Club',
  'Taekwondo Club',
  // 'Tough Mudder',
  // 'Triathlon',
  // 'Water Polo Club',
  "Women's Basketball Club (Women's Hoopsters)",
  "Women's Ice Hockey Club",
  "Women's Lacrosse Club",
  "Women's Soccer Club",
  "Women's Tennis Club",
  "Women's Ultimate Frisbee Club",
  "Women's Volleyball Club",
  "Women's Wrestling Club",
  'Wushu Club',
  'Pickleball Club',
  // 'XFit',
];
